var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"index paperOne"},[_c('div',{staticStyle:{"margin-bottom":"10px"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.dialogFormVisible = true}}},[_vm._v("+添加论文")])],1),_c('el-table',{staticClass:"ly_table",attrs:{"cell-style":{ 'text-align': 'center' },"header-cell-style":{
        background: '#F5F6F7',
        color: '#363E4D',
        'text-align': 'center',
      },"data":_vm.form1}},[_c('template',{slot:"empty"},[_c('img',{attrs:{"src":require("@/assets/img/table.png"),"alt":"没有数据","srcset":""}})]),_c('el-table-column',{attrs:{"prop":"thesisName","label":"名称"}}),(_vm.personType)?_c('el-table-column',{attrs:{"prop":"name","label":"论文合作者"}}):_c('el-table-column',{attrs:{"prop":"name","label":"论文合作者"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _vm._l((scope.row.name),function(item,index){return _c('span',{key:index},[_vm._v(_vm._s(item)+"  ")])})}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"120","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('button-table',{attrs:{"content":"编辑","icon":"jr-iconjr-icon-edit"},on:{"buttonClick":function($event){return _vm.openEdit(scope.row)}}}),_c('button-table',{attrs:{"content":"删除","icon":"jr-iconjr-icon-delect"},on:{"buttonClick":function($event){return _vm.deleteEx(scope.row)}}})]}}])})],2),_c('el-pagination',{attrs:{"current-page":_vm.queryInfo.pageNum,"page-sizes":[3, 5, 10],"page-size":_vm.queryInfo.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}}),_c('el-dialog',{attrs:{"title":"添加论文","visible":_vm.dialogFormVisible,"center":""},on:{"update:visible":function($event){_vm.dialogFormVisible=$event},"close":_vm.handleClose}},[_c('el-form',{ref:"form",staticClass:"demo-dynamic",attrs:{"model":_vm.form,"label-width":"100px"}},[_c('el-form-item',{attrs:{"prop":"thesisName","label":"论文名称","rules":[
            { required: true, message: '请输入论文名称', trigger: 'blur' } ]}},[_c('el-input',{model:{value:(_vm.form.thesisName),callback:function ($$v) {_vm.$set(_vm.form, "thesisName", $$v)},expression:"form.thesisName"}})],1),_vm._l((_vm.form.name),function(item,index){return _c('el-form-item',{key:item.key,attrs:{"label":"论文合作者","prop":'name.' + index + '.val',"rules":{
            required: true,
            message: '论文合作者不能为空',
            trigger: 'blur',
          }}},[_c('div',{staticStyle:{"display":"flex"}},[_c('el-input',{model:{value:(item.val),callback:function ($$v) {_vm.$set(item, "val", $$v)},expression:"item.val"}}),_c('div',{on:{"click":function($event){$event.preventDefault();return _vm.removeDomain(item)}}},[_c('button-table',{attrs:{"content":"删除","icon":"jr-iconjr-icon-clean"}})],1)],1)])}),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.submitForm('form')}}},[_vm._v("提交")]),_c('el-button',{on:{"click":_vm.addDomain}},[_vm._v("添加论文合作者")])],1)],2)],1),_c('el-dialog',{attrs:{"title":"编辑论文","visible":_vm.dialogFormVisible1},on:{"update:visible":function($event){_vm.dialogFormVisible1=$event},"close":_vm.handleClose}},[_c('el-form',{ref:"form",staticClass:"demo-dynamic",attrs:{"model":_vm.form,"label-width":"100px"}},[_c('el-form-item',{attrs:{"prop":"thesisName","label":"论文名称","rules":[
            { required: true, message: '请输入论文名称', trigger: 'blur' } ]}},[_c('el-input',{model:{value:(_vm.form.thesisName),callback:function ($$v) {_vm.$set(_vm.form, "thesisName", $$v)},expression:"form.thesisName"}})],1),_vm._l((_vm.form.name),function(item,index){return _c('el-form-item',{key:item.key,attrs:{"label":"论文合作者","prop":'name.' + index + '.val',"rules":{
            required: true,
            message: '论文合作者不能为空',
            trigger: 'blur',
          }}},[_c('div',{staticStyle:{"display":"flex"}},[_c('el-input',{model:{value:(item.val),callback:function ($$v) {_vm.$set(item, "val", $$v)},expression:"item.val"}}),_c('div',{on:{"click":function($event){$event.preventDefault();return _vm.removeDomain(item)}}},[_c('button-table',{attrs:{"content":"删除","icon":"jr-iconjr-icon-clean"}})],1)],1)])}),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.submitForm1('form')}}},[_vm._v("提交")]),_c('el-button',{on:{"click":_vm.addDomain}},[_vm._v("添加论文合作者")])],1)],2)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="labelInfo">
    <el-form
      :model="peronLabelParam"
      label-width="130px"
      :rules="rules"
      ref="labelInfoForm"
    >
      <el-form-item label="关键词版本：">
        <el-select
          v-model="peronLabelParam.keywordVersion"
          placeholder="请选择"
          filterable
          :filter-method="add"
          style="width: 275px"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.keywordVersion"
            :value="item.keywordVersion"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <!-- 遍历数据7 -->
      <div
        class="eachData"
        v-for="(item, index) in peronLabelParam.personKeywordAddParamList"
        :key="index"
      >
        <el-form-item label="关键词：" prop="keyword">
          <el-input v-model="item.keyword" class="lager-input" />
          <span style="color: #4e93fb" @click="addArr(item)">
            <i class="el-icon-circle-plus-outline"></i>
          </span>
          <span v-if="index > 0" style="color: #ff7575" @click="delArr(index)">
            <i class="el-icon-remove-outline"></i>
          </span>
        </el-form-item>
        <el-form-item label="关键词数据：">
          <el-input v-model="item.keywordDescribe" style="width: 275px" />
        </el-form-item>
        <!-- 4 -->
        <div
          class="labelAdd"
          v-for="(labelItem, labeliIndex) in item.personLabelList"
          :key="labeliIndex"
        >
          <el-form-item label="标签内容：" prop="labelName">
            <el-select
              v-model="labelItem.labelName"
              placeholder="请选择"
              style="width: 275px"
              @change="
                (value) => {
                  changeValue(value, labelItem, item, labeliIndex);
                }
              "
              filterable
              :filter-method="searchSelect"
            >
              <!-- 展示标签组/标签内容 -->
              <el-option
                v-for="item in arr"
                :key="item.id"
                :label="item.groupLabelType + '/' + item.labelName"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <span style="color: #4e93fb" @click="addLabel(item)">
              <i class="el-icon-circle-plus-outline"></i>
            </span>
            <span
              v-if="labeliIndex > 0"
              style="color: #ff7575"
              @click="delLabel(index, labeliIndex)"
            >
              <i class="el-icon-remove-outline"></i>
            </span>
          </el-form-item>
          <!-- 上级标签 -->
          <div class="supLabel">
            <span>上级标签：</span>
            <el-breadcrumb separator="/">
              <el-breadcrumb-item>{{
                labelItem.superiorLabel ? labelItem.superiorLabel : "无"
              }}</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <el-form-item label="标签组编号：">
            <el-input
              v-model="labelItem.groupLabelId"
              style="width: 275px"
              disabled
            />
          </el-form-item>
          <el-form-item label="标签组名称：">
            <el-input
              v-model="labelItem.groupLabelName"
              style="width: 275px"
              disabled
            />
          </el-form-item>
          <el-form-item label="标签编号：">
            <el-input
              v-model="labelItem.labelId"
              style="width: 275px"
              disabled
            />
          </el-form-item>
        </div>
        <!-- 4 -->
      </div>
      <!-- 遍历数据7 -->
    </el-form>
  </div>
</template>

<script>
import { selectKeywordVersion, selectLikeLabel } from "@/api/enterprise";
export default {
  name: "LabelInfo",
  props: {},
  data() {
    return {
      rules: {
        labelName: [
          { required: true, message: "请选择标签内容", trigger: "change" },
        ],
      },
      options: [],
      labelOptions: [], // 标签内容的总数组（从后端返回的数据）
      value: "",
      // 表单数据、初始值
      peronLabelParam: {
        personId: "", // 案例主键
        keywordVersion: "", // 关键字版本
        // type: null,
        personKeywordAddParamList: [
          {
            personLabelList: [
              {
                groupLabelId: "", // 标签组编号
                groupLabelName: "", // 标签组名称
                labelId: "", // 标签编号
                labelName: "", // 标签内容
                personnelKeywordId: null,
                id: null,
                superiorLabel: null, // 上级标签
                labelStatus: 1, // 判断当前选中 1为选中的值 0为其他
              },
            ],
            keyword: "", // 关键词
            keywordDescribe: "", // 关键词数据
          },
        ],
      },
      labelArr: [], // 具体点击添加的标签数组
      // type: null, // 点击的是哪个＋号（关键词 1， 标签内容 2）
      allLabelList: [], // 标签内容的总数组（从后端返回的数据）
      arr: [],
    };
  },
  watch: {
    // 监听标签内容，如果标签内容有值就将校验关掉
    "peronLabelParam.personKeywordAddParamList": {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          if (!newVal[0].personLabelList) return;
          // 如果选择框的值改变了就将校验关闭
          newVal[0].personLabelList[0].labelName
            ? (this.rules.labelName[0].required = false)
            : (this.rules.labelName[0].required = true);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.selectKeywordVersionFn();
    this.selectLikeLabelFn();
  },
  methods: {
    // 搜索
    async searchSelect(val) {
      const res = await selectLikeLabel({ param: val });
      if (res.code == 200) {
        this.arr = [];
        // this.labelOptions = res.data;
        this.labelOptions.push(...res.data);
        res.data.forEach((item) => {
          this.arr.push(item.labelKeywordResultList[0]);
        });
      }
    },
    // 添加标签内容等六个字段
    addArr(item) {
      this.peronLabelParam.personKeywordAddParamList.push({
        personLabelList: [
          {
            groupLabelId: "", // 标签组编号
            groupLabelName: "", // 标签组名称
            labelId: "", // 标签编号
            labelName: "", // 标签内容
            personnelKeywordId: null,
            superiorLabel: null, // 上级标签
          },
        ],
        keyword: "", // 关键词
        keywordDescribe: "", // 关键词数据
        type: null,
      });
    },
    delArr(index) {
      this.peronLabelParam.personKeywordAddParamList.splice(index, 1);
    },
    // 添加标签内容等四个字段
    addLabel(item) {
      if (item.personLabelList.length >= this.labelOptions.length) {
        this.$message.warning("不能超过标签内数量");
        return;
      }
      // 拿到具体点击添加的标签数组
      this.labelArr = this.peronLabelParam.personKeywordAddParamList.find(
        (i) => i == item
      ).personLabelList;
      // 在数组中添加四个标签
      this.labelArr.push({
        groupLabelId: "", // 标签组编号
        groupLabelName: "", // 标签组名称
        labelId: "", // 标签编号
        labelName: "", // 标签内容
        personnelKeywordId: null,
        superiorLabel: null, // 上级标签
      });
    },
    delLabel(index, labelIndex) {
      this.peronLabelParam.personKeywordAddParamList[
        index
      ].personLabelList.splice(labelIndex, 1);
    },
    // 关键词版本
    async selectKeywordVersionFn() {
      const res = await selectKeywordVersion();
      if (res.code == 200) {
        this.options = res.data;
      }
    },
    // 企业标签信息详情
    async selectLikeLabelFn() {
      const res = await selectLikeLabel();
      if (res.code == 200) {
        this.labelOptions = res.data;
        res.data.forEach((item) => {
          this.arr.push(item.labelKeywordResultList[0]);
        });
      }
    },
    // 根据标签内容回显其他三个禁用输入框(val为当前值的id,labelItem为当前选中的标签对象,itemCurr为当前选中的标签对象上级对象)
    changeValue(value, labelItem, itemCurr, labeliIndex) {
      //判断当前对象数组是否已存在该标签
      let obj = itemCurr.personLabelList.filter(
        (item) => item.labelId == value
      )[0];
      //有重复且当前标签内容不为空则不添加
      if (obj) {
        this.$message.warning("不允许出现重复标签");
        itemCurr.personLabelList[labeliIndex] = {
          groupLabelId: "", // 标签组编号
          groupLabelName: "", // 标签组名称
          labelId: "", // 标签编号
          labelName: "", // 标签内容
          superiorLabel: "", // 上级标签
        };
        return;
      }
      let addobj = {};
      let superiorLabel = null;
      for (const item of this.labelOptions) {
        let obj = item.labelKeywordResultList.filter(
          (item) => item.id == value
        )[0];
        if (obj) {
          superiorLabel = item.superiorLabel ? item.superiorLabel : "无";
          addobj = obj;
          break;
        }
      }
      //没有重复的则赋值;
      let notDataArr = {
        groupLabelId: addobj.groupLabelPrefix, // 标签组编号
        groupLabelName: addobj.groupLabelType, // 标签组名称
        labelId: addobj.id, // 标签编号
        labelName: addobj.groupLabelType + "/" + addobj.labelName, // 标签内容
        superiorLabel: superiorLabel, // 上级标签
      };
      this.$set(itemCurr.personLabelList, labeliIndex, notDataArr);
      // 如果选择框的值改变了就将校验关闭
      this.rules.labelName[0].required = false;
      return;
    },
    // 关键词版本输入添加
    add(currentVal) {
      this.peronLabelParam.keywordVersion = currentVal;
    },
  },
  mounted() {
    document.getElementsByClassName("el-tabs__content")[0].style.minHeight =
      document.body.clientHeight - 262 + "px";
  },
};
</script>

<style lang="less" scoped>
.labelInfo {
  .el-form-item {
    margin-bottom: 18px;
  }
  .el-form-item:last-child {
    margin-bottom: 0px;
  }
  .el-icon-circle-plus {
    font-size: 18px;
    color: rgba(30, 159, 255, 1);
    cursor: pointer;
    margin-left: 8px;
  }
  .labelAdd {
    // width: 500px;
    width: 623px;
    padding: 10px 0;
    margin: 20px 0 20px 100px;
    border-radius: 4px;
    background: rgba(248, 248, 248, 1);
  }
  // .eachLabel {
  //   margin-top: 20px;
  //   margin-bottom: 20px;
  // }
  /deep/.el-input__inner {
    width: 275px !important;
  }
  /deep/.el-input {
    width: 275px !important;
  }
  .eachData {
    margin: 20px 0;
  }

  .el-icon-circle-plus {
    font-size: 18px;
    color: rgba(30, 159, 255, 1);
    cursor: pointer;
    margin-left: 4px;
  }
  /deep/ .el-upload--picture-card {
    width: 135px;
    height: 92px;
    line-height: 92px;
  }
  .editorbar_box {
    width: 700px;
    height: 184px;
    border-radius: 4px;
    border: 1px;
    .editBar {
      width: 700px;
      height: 184px;
    }
    .editor {
      .text {
        max-height: 184px !important;
      }
    }
  }
  /deep/ .el-icon-circle-plus-outline {
    font-size: 31px;
    vertical-align: middle;
    color: #698eef;
    cursor: pointer;
    padding-left: 10px;
  }
  /deep/ .el-icon-remove-outline {
    font-size: 31px;
    vertical-align: middle;
    color: red;
    cursor: pointer;
    padding-left: 10px;
  }
}
.supLabel {
  font-size: 14px !important;
  line-height: 1 !important;
  display: flex;
  margin-left: 130px;
  margin-bottom: 18px;
  color: #409eff;
  /deep/.el-breadcrumb__inner {
    color: #409eff;
  }
  /deep/.el-breadcrumb__inner:hover {
    color: #409eff;
  }
  /deep/.el-breadcrumb__separator {
    color: #409eff;
  }
  /deep/.el-breadcrumb__separator:hover {
    color: #409eff;
  }
}
</style>

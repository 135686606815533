<template>
  <div>
    <div class="index patentOne">
      <div style="margin-bottom: 10px">
        <el-button type="primary" @click="dialogFormVisible = true"
          >+添加专利</el-button
        >
      </div>
      <el-table
        class="ly_table"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{
          background: '#F5F6F7',
          color: '#363E4D',
          'text-align': 'center',
        }"
        :data="form2"
      >
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset />
        </template>
        <el-table-column prop="patentName" label="专利名称"></el-table-column>
        <el-table-column label="操作" width="120" align="center">
          <template slot-scope="scope">
            <button-table
              @buttonClick="openEdit(scope.row, scope.$index)"
              content="编辑"
              icon="jr-iconjr-icon-edit"
            />
            <button-table
              @buttonClick="deleteEx(scope.row, scope.$index)"
              content="删除"
              icon="jr-iconjr-icon-delect"
            />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[3, 5, 10]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
      <el-dialog
        title="添加专利"
        :visible.sync="dialogFormVisible"
        center
        @close="handleClose"
      >
        <el-form :model="form" :rules="formRules" ref="ruleForm">
          <el-form-item label="专利名称:" label-width="120px" prop="patentName">
            <el-input v-model="form.patentName" style="width: 430px"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="addPersonEx('ruleForm')"
            >确 定</el-button
          >
          <el-button @click="dialogFormVisible = false">取 消</el-button>
        </div>
      </el-dialog>
      <el-dialog
        title="编辑专利"
        :visible.sync="dialogFormVisible1"
        center
        @close="handleClose"
      >
        <el-form :model="editform" :rules="formRules" ref="editruleForm">
          <el-form-item label="专利名称:" label-width="120px" prop="patentName">
            <el-input
              v-model="editform.patentName"
              style="width: 430px"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="editEx('editruleForm')"
            >确 定</el-button
          >
          <el-button @click="cancelEx('editruleForm')">取 消</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- <div class="bottom" v-if="personType">
        <el-button @click="cancelItem" type="primary">确定</el-button>
        <el-button type="info" @click="cancelItem">取消</el-button>
    </div>-->
  </div>
</template>

<script>
import ButtonTable from "../../../../components/ButtonTable.vue";
import {
  getPatent,
  addPatent,
  editPatent,
  deletePatent,
} from "@/api/talent.js";
import { pagination } from "../../../../mixins/pagination";
export default {
  mixins: [pagination],
  components: {
    ButtonTable,
  },
  data() {
    return {
      index: 0,
      form: {
        patentName: "", //专利名称
        createName: "", //创建人名字
        createTime: "", //创建时间
        updateName: "", //修改人名字
        updateTime: "", //修改时间
      },
      formRules: {
        patentName: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
      },
      queryInfo: {
        personCode: "", //人才编号
        pageSize: 10,
        pageNum: 1,
      },
      // total: 0,
      form2: [],
      dialogFormVisible: false,
      dialogFormVisible1: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      editform: "",
    };
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem("token");
    },
  },
  props: {
    // 编辑数据
    personData: {
      type: Object,
    },
    // 0 新增 1 编辑
    personType: {
      type: Number,
    },
    // 新增唯一id
    saveId: {
      type: String,
    },
  },
  created() {
    if (this.personType || this.saveId) {
      this.search();
    }
  },
  methods: {
    // 清空表单
    deleteForm() {
      this.form = {
        patentName: "", //专利名称
        createName: "", //创建人名字
        createTime: "", //创建时间
        updateName: "", //修改人名字
        updateTime: "", //修改时间
      };
      this.dialogFormVisible = false;
      this.dialogFormVisible1 = false;
    },
    // 查询
    async search() {
      if (this.personType || this.saveId) {
        this.queryInfo.personCode = this.personData.personCode
          ? this.personData.personCode
          : this.saveId;
      }
      const res = await getPatent(this.queryInfo);
      if (res.code === 200) {
        this.form2 = res.data.list;
        this.total = res.data.total;
      }
    },
    // 添加工作经历
    addPersonEx(formName) {
      if (this.personType || this.saveId) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            Object.assign(this.form, {
              Authorization: this.Authorization,
              personCode: this.personData.personCode
                ? this.personData.personCode
                : this.saveId,
              personType: "学术机构人才",
            });
            const res = await addPatent(this.form);
            if (res.code === 200) {
              this.dialogFormVisible = false;
              this.deleteForm();
              this.search();
            }
          }
        });
      } else {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            var data = this.form;
            this.form2.push(data);
            this.deleteForm();
          }
        });
      }
    },
    // 删除工作经历
    async deleteEx(data, index) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示").then(
        async () => {
          if (this.personType || this.saveId) {
            const deleteForm = {
              id: data.id,
              personCode: data.personCode ? data.personCode : this.saveId,
              personType: "学术机构人才",
            };
            const res = await deletePatent(deleteForm);
            if (res.code == 200) {
              this.search();
            }
          } else {
            this.form2.splice(index, 1);
          }
        }
      );
    },
    // 打开编辑界面
    openEdit(data, index1) {
      this.index = index1;
      this.dialogFormVisible1 = true;
      this.editform = JSON.parse(JSON.stringify(data));
      Object.assign(this.editform, {
        Authorization: this.Authorization,
      });
    },
    // 修改工作经历
    editEx(formName, index) {
      if (this.personType || this.saveId) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            const res = await editPatent(this.editform);
            if (res.code === 200) {
              this.deleteForm();
              this.search();
            }
          }
        });
      } else {
        this.form2[this.index] = this.editform;
        this.dialogFormVisible1 = false;
      }
    },
    cancelEx(formName) {
      this.dialogFormVisible1 = false;
      this.$refs[formName].resetFields();
    },
    handleClose() {
      this.deleteForm();
    },
    cancelItem() {
      this.$router.go(-1);
    },
  },
  mounted() {
    if (this.personType) {
      document.getElementsByClassName("patentOne")[0].style.height =
        document.body.clientHeight - 320 + "px";
    }
  },
};
</script>

<style scoped lang="less">
.el-input__inner {
  width: 200px;
}
.el-button {
  padding: 12px 15px;
}
/deep/.el-dialog {
  width: 645px;
}
.index {
  // height: 560px;
  overflow: auto;
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    padding: 8px 10px;
    margin: 0 20px;
  }
}
</style>

<template>
  <div class="user">
    <div class="tab_txt">
      <!-- <div class="storeCenter_item_top">
        <div class="storeCenter_item_top_left">
          <img :src="ginsenginfo.image" alt="" />
          <div class="storeCenter_item_top_left_flex">
            <div style="display: flex; align-items: center">
              <h5 style="display: inline-block; margin: 0; font-size: 18px">
                {{ ginsenginfo.informationName }}
              </h5>
            </div>
            <div style="padding-top: 10px">
              <span v-if="ginsenginfo.informationType == 1"> 内容类型: 应用场景 </span>
              <span v-if="ginsenginfo.informationType == 2"> 内容类型: 数智技术 </span>
              <span v-if="ginsenginfo.informationType == 3"> 内容类型: 行业动态 </span>
              <span v-if="ginsenginfo.informationType == 4"> 内容类型: 数智案例 </span>
              <span v-if="ginsenginfo.informationType == 5"> 内容类型: 数智产品 </span>
              <span v-if="ginsenginfo.informationType == 6"> 内容类型: 服务商 </span>
              <span v-if="ginsenginfo.informationType == 7"> 内容类型: 政策解读 </span>
              <span v-if="ginsenginfo.informationType == 8"> 内容类型: 组织人才 </span>
            </div>
            <p class="address" style="display: flex; align-items: center">
              <span>发布机构:</span>
              <img
                style="
                  width: 16px;
                  height: 16px;
                  border-radius: 20px;
                  margin-left: 10px;
                  margin-right: 5px;
                "
                :src="ginsenginfo.logo"
                alt=""
              />
              <span>{{ ginsenginfo.issueName }}</span>
            </p>
          </div>
        </div>
      </div> -->
      <div class="storeCenter_item_top_right">
        <div class="history_label">历史匹配标签</div>

        <div v-if="ginsenginfo.matchedLabels">
          <el-tag
            class="tag_cuposer"
            :key="indexs"
            v-for="(tag, indexs) in ginsenginfo.matchedLabels"
            closable
            :disable-transitions="false"
            @close="handleClose(indexs)"
          >
            {{ tag }}
          </el-tag>
        </div>
      </div>
    </div>
    <div class="automatic_label">
      <div class="automatic_label_one">
        <div class="automatic_label_one_left">
          <i class="el-icon-setting"></i>
          <span class="inco_txt"
            >① 输 入 文 本 ， 系 统 匹 配 生 成 新 标 签</span
          >
        </div>
        <div class="automatic_label_one_center">
          <div class="automatic_label_one_center_top" v-loading="loading">
            <div class="blue_title">
              <span>匹配文本</span>
            </div>
            <div class="textarea_flex">
              <div class="textarea_input">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 10, maxRows: 11 }"
                  placeholder="请输入匹配文本"
                  v-model="param.text"
                >
                </el-input>
              </div>
              <div class="textarea_input right_back">
                <article-content :textHtml="textHtml" />
              </div>
            </div>
          </div>
          <div class="new_label" @click="newlabel">
            <span>生成新标签</span>
          </div>
        </div>
        <div class="automatic_label_one_right">
          <div class="tag_flex">
            <div class="tag_group">
              <span>待匹配标签组</span>
            </div>
            <div class="ckeckbox_text">
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange"
                >全选</el-checkbox
              >
            </div>
          </div>
          <div class="tag_center">
            <el-checkbox-group v-model="param.groupLabelTypes">
              <el-checkbox
                style="margin-top: 10px"
                v-for="(el, index) in codelist"
                :label="el"
                :key="index"
                >{{ el }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <!-- 新增关键词 -->
      <!-- <div class="keywordVersion">
        <div class="top">
          <span class="title">关键词：</span>
          <span class="time">
            <i class="el-icon-refresh"></i>
            更新时间：{{ time }}
          </span>
        </div>
        <div class="bom">{{ keywordsList }}</div>
      </div> -->
      <div class="automatic_label_two">
        <div class="automatic_label_one_left">
          <i class="el-icon-success"></i>
          <span class="inco_txt">② 挑 选 匹 配 生 成 的 新 标 签</span>
        </div>
        <div class="automatic_label_one_center bom_other">
          <div class="automatic_label_one_center_top">
            <div class="blue_title">
              <div v-if="newlabelList.length">
                <div>匹配标签组:</div>
                <div>
                  <span style="font-size: 16px"
                    >{{ groupType.toString() }}({{ newlabelList.length }})</span
                  >
                </div>
              </div>

              <div v-else>匹配标签组</div>
              <div class="group_check_flex">
                <span style="color: #999999; font-size: 14px"
                  >请选择您想要的标签加入标签池</span
                >
                <div class="ckeckbox_text" style="margin-left: 10px">
                  <el-checkbox
                    :indeterminate="isNewlabel"
                    v-model="newlabeAll"
                    @change="handlelabeAllChange"
                    >全选</el-checkbox
                  >
                </div>
              </div>
            </div>
            <div class="checkbox_tag">
              <el-checkbox-group v-model="labelparam">
                <el-checkbox
                  style="margin-top: 10px"
                  v-for="(el, index) in newlabelList"
                  :label="el"
                  :key="index"
                >
                  <el-tooltip placement="bottom">
                    <div class="tooltip" slot="content">
                      <span class="tooltip_top">{{ el.labelType }}</span>
                      <span>{{ el.superiorLabel }}</span>
                    </div>
                    <el-button class="hover_text" type="text">
                      <div v-html="el.labelHtml"></div>
                    </el-button>
                  </el-tooltip>
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div class="new_label" @click="addlabel">
            <span>加入标签池</span>
          </div>
        </div>
      </div>
      <div class="automatic_label_two">
        <div class="automatic_label_one_left">
          <i class="el-icon-s-claim"></i>
          <span class="inco_txt">③ 本 次 匹 配 已 选 标 签 展 示</span>
        </div>
        <div class="automatic_label_one_center bom_other">
          <div class="automatic_label_one_center_top" v-loading="loading">
            <div class="blue_title">
              <span>标签池({{ parampool.matchLabelResultList.length }})</span>
            </div>
            <div class="checkbox_tag">
              <el-tag
                class="tag_cuposer"
                :key="indexs"
                v-for="(tag, indexs) in parampool.matchLabelResultList"
                closable
                :disable-transitions="false"
                @close="handleCloses(indexs)"
              >
                {{ tag.labelName }}
              </el-tag>
            </div>
          </div>
          <div class="new_label" @click="savelabel">
            <span>保存已选标签</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Vue from 'vue'
// 改动
import Vue from "vue/dist/vue.esm.js";

import {
  selectById,
  getBylabelId,
  insertMatchLabel,
  setLabelToRedis,
  getGroupLabel,
  updateMatchedCompanyLabel,
} from "@/api/content.js";
// import { updateMatchedInformationLabel } from "@/api/datement.js";
// import { getClientEnterpriseOne } from "@/api/customCompany";
import {
  insertMatchPlanLabel,
  updateMatchedInformationLabel,
  findHistroyMatchedLabelByPlan,
} from "@/api/caseData.js";
import {
  insertAndUpdatePersonMatchedLabel,
  findHistroyMatchedLabelByPerson,
} from "@/api/talent.js";
export default {
  props: {
    activeId: {
      type: String,
    },
    companyId: {
      type: String,
    },
    type: {
      type: Number,
    },
    companyCode: {
      type: Number,
    },
    // 新增唯一id
    saveId: {
      type: String,
    },
    // 编辑唯一id
    personData: {
      type: Object,
    },
  },
  data() {
    return {
      ginsenginfo: {
        matchedLabels: null,
      },
      labeList: [], //标签数组
      loading: false,
      param: {
        groupLabelTypes: [], //待匹配标签组
        id: "", //企业主键/资讯主键
        text: "", //匹配文本
      },
      groupType: [], //已匹配标签组
      codelist: [], //标签组类别
      newlabelList: [], //生成的新标签
      labelparam: [], //选择新增标签
      // tagpoolist: [], //标签池已选
      parampool: {
        personId: this.companyId, //资讯主键
        type: null, //智参1 数智2
        keywords: [], //关键词
        matchLabelResultList: [],
      }, //标签池-保存已选标签
      isIndeterminate: false,
      checkAll: false, //全选待匹配标签组
      isNewlabel: false,
      newlabeAll: false, //全选已匹配标签
      textHtml: "", //标签高亮
      disabled: true, //控制按钮是否禁用
      keywordsList: null, //生成新标签的关键词数据
      time: "", //更新时间
    };
  },
  components: {
    ArticleContent: {
      props: {
        textHtml: {
          type: String,
        },
      },
      // render(h) {
      //   const com = Vue.extend({
      //     template: `<div >${this.textHtml}</div>`
      //   })

      //   return h(com, {})
      // },

      // 改动
      render(h) {
        const compiled = Vue.compile(`<div>${this.textHtml}</div>`);
        const com = {
          render: compiled.render,
          staticRenderFns: compiled.staticRenderFns,
        };

        return h(com);
      },
    },
  },
  async created() {
    // this.selectPartnerById()
    // this.getBylabelId()
    this.getGroupLabel();

    // console.log(this.companyId, "companyId");

    // 处理更新时间
    const now = new Date();
    this.time = now.toLocaleString();

    // console.log(now.toLocaleString()); // 输出本地化的日期和时间字符串

    this.searchSelectPartnerById();

    // 查询
  },
  methods: {
    //触发
    sing() {
      // this.selectPartnerById()
      // this.getBylabelId()
      this.getGroupLabel();
    },

    //标签类别
    async getGroupLabel() {
      const res = await getGroupLabel();
      if (res.code == 200) {
        this.codelist = res.data;
      } else {
        // this.$message.error(res.data.message);
      }
    },
    //头部详情
    async selectPartnerById() {
      const res = await selectById({ id: this.activeId });
      if (res.data.resultCode == 200) {
        this.ginsenginfo = res.data.data;
        if (this.ginsenginfo.imageList) {
          this.ginsenginfo.image = this.ginsenginfo.imageList[0];
        }
      }
    },
    // 查询历史匹配标签
    async searchSelectPartnerById() {
      const res = await findHistroyMatchedLabelByPerson({
        personId: this.personData.personCode,
      });

      if (res.code == 200) {
        // 将查询到的历史匹配标签赋值
        // this.ginsenginfo.matchedLabels = res.data.matchedLabelList;
        this.ginsenginfo.matchedLabels = res.data;
      }
      // 将查询到的历史匹配标签赋值
      // this.ginsenginfo.matchedLabels = res.data.matchedLabelList;
    },
    //查询标签数
    async getBylabelId() {
      const res = await getBylabelId({ id: this.activeId });
      if (res.data.resultCode == 200) {
        this.labeList = res.data.data;
      }
    },
    //全选标签组
    handleCheckAllChange(val) {
      this.param.groupLabelTypes = [];
      if (val) {
        this.codelist.forEach((item) => {
          this.param.groupLabelTypes.push(item);
        });
      } else {
        this.codelist.forEach((item) => {
          this.param.groupLabelTypes = [];
        });
      }
      this.isIndeterminate = val;
    },
    //全选新标签
    handlelabeAllChange(val) {
      this.labelparam = [];
      if (val) {
        this.newlabelList.forEach((item) => {
          this.labelparam.push(item);
        });
      } else {
        this.newlabelList.forEach((item) => {
          this.labelparam = [];
        });
      }
      this.isNewlabel = val;
    },
    async handleClose(indexs) {
      this.ginsenginfo.matchedLabels.splice(indexs, 1);
      const query = {
        id: this.companyId,
        labelList: this.ginsenginfo.matchedLabels,
        type: this.type,
      };
      this.$alert("请确认是否删除已匹配标签，删除后不可恢复！")
        .then(function () {
          return insertAndUpdatePersonMatchedLabel(query);
        })
        .then(() => {
          // this.selectPartnerById();
          this.searchSelectPartnerById();
          this.$message.success("已删除");
        })
        .catch(() => {
          // this.selectPartnerById();
          this.searchSelectPartnerById();
        });
    },
    //查询标签池的标签
    handleCloses(indexs) {
      this.parampool.matchLabelResultList.splice(indexs, 1);
    },
    //生成新标签
    async newlabel() {
      if (this.param.groupLabelTypes.length < 1) {
        this.$message.warning("请选择待匹配标签组");
        return;
      }
      if (!this.param.text) {
        this.$message.warning("请输入文本");
        return;
      }
      this.loading = true;
      this.param.id = this.personData.personCode
        ? this.personData.personCode
        : this.saveId;
      const res = await setLabelToRedis(this.param);
      if (res.code == 200) {
        this.newlabelList = res.data.matchLabelResultList;

        // 去重
        var keyArr = res.data.keywords;
        this.keywordsList = keyArr.filter((value, index, self) => {
          return self.indexOf(value) === index;
        });
        // 关键词
        this.parampool.keywords = this.keywordsList;

        // 将查询的标签组赋值给groupType
        this.groupType = this.param.groupLabelTypes;
        if (this.newlabelList.length == 0) {
          this.$message.warning("此文本暂无匹配的新标签");
          // 将加入标签池与保存已选标签按钮置为禁用（灰）
        } else {
          this.textHtml = this.param.text; //把匹配文本赋值给高亮文本

          this.newlabelList.forEach((el, index) => {
            if (el.labelName) {
              el.labelName = el.labelName.split("-")[1];
              let str = el.labelName.replace(
                eval("/" + el.keyword + "/g"),
                ` <span  style="color:#03A600;font-weight:550;cursor: pointer;" >${el.keyword}</span>`
              ); //标签关键词高亮
              this.$set(el, "labelHtml", str);
              //进行关键词高亮并移入显示标签
            }
          }); //根据-截取字符串标签
          //深拷贝数组不保存
          let list = JSON.parse(JSON.stringify(this.newlabelList)); //关键词高亮数组
          let arr1 = [];
          //通过循环使关键词一样的标签整合到一起
          for (var i = 0; i < list.length; i++) {
            for (var j = list.length - 1; j >= 0; j--) {
              if (list[i].labelName && list[j].labelName) {
                if (
                  list[i].keyword == list[j].keyword &&
                  list[i].labelName != list[j].labelName
                ) {
                  list[i].labelName =
                    list[i].labelName + "," + list[j].labelName;
                }
              }
            }
          }

          //关键词一样的可去重
          list.forEach((el) => {
            if (!arr1.some((e) => e.keyword == el.keyword)) {
              arr1.push(el);
            }
          });
          //关键词高亮并可鼠标移入展示标签
          arr1.forEach((el, index) => {
            this.textHtml = this.textHtml.replace(
              eval("/" + el.keyword + "/g"),
              `<div style="display: inline-block;"><el-tooltip class="item" effect="dark"   placement="top-start">
                       <div slot="content">
                        ${el.labelName}
                        </div>
                      <el-button style="height:20px;color:#03A600; font-weight:550;" type="text" >${el.keyword}</el-button>
                   </el-tooltip></div>`
            );
          });
        }
        this.loading = false;
      } else {
        this.$message.error(res.message);
        this.loading = false;
      }
    },

    //加入标签池
    addlabel() {
      this.parampool.matchLabelResultList = this.labelparam;
    },
    // 保存已选标签
    async savelabel() {
      if (this.parampool.matchLabelResultList.length < 1) {
        this.$message.warning("请选择需要保存的标签");
        return;
      }
      this.loading = true;

      // this.parampool.id = this.activeId;
      this.parampool.personId = this.personData.personCode
        ? this.personData.personCode
        : this.saveId;
      // this.parampool.type = this.type;
      const res = await insertAndUpdatePersonMatchedLabel(this.parampool);
      if (res.code == 200) {
        this.loading = false;
        // this.selectPartnerById();
        this.searchSelectPartnerById();
        this.$message.success("匹配成功");

        // 跳转到首页
        this.$router.push({
          name: "Talents",
          params: {
            personType: 1,
            // queryInfo: this.queryInfo,
          },
        });
      } else {
        this.$message.error(res.message);
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.user {
  display: block !important;
}
::v-deep .el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 100%;
  font-size: inherit;
  color: #606266;
  background-color: #fff;
  background-image: none;
  border: 1px solid #fff;
  border-radius: 4px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hover_text :hover {
  color: #448aff;
}
.hover_text {
  color: #212121;
}
.tooltip {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  .tooltip_top {
    font-size: 17px;
    font-weight: 550;
    padding-bottom: 10px;
  }
}
.office {
  border: 1px solid #ff7d18;
  color: #ff7d18;
  background: #fff2e8;
  display: inline-block;
  padding: 0px 5px;
  margin-right: 10px;
}
.form_info {
  background: #ffffff;
  padding: 20px;
}

.tab_txt {
  padding: 0px 20px 20px 20px;
  .storeCenter_item_top_right {
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    color: #146aff;
    height: 136px;
    padding: 0px 20px 20px 20px;
    overflow: auto;
    .history_label {
      font-size: 20px;
      padding: 10px 0px 0px 0px;
    }
    .tag_cuposer {
      margin-right: 10px;
      margin-top: 10px;
      cursor: pointer;
    }
  }
}
.automatic_label {
  border-top: 20px solid #f6f7fb;
  padding: 20px;
  background: #fff;
  /deep/.el-checkbox__inner {
    width: 18px;
    height: 18px;
  }
  /deep/.el-checkbox__label {
    color: #333;
  }
  /deep/.el-checkbox__inner::after {
    height: 10px;
    left: 6px;
  }
  .automatic_label_one {
    display: flex;
    .automatic_label_one_left {
      padding-top: 30px;
      height: 385px;
      width: 71px;
      background: #f6f6f6;
      display: flex;
      flex-direction: column;
      align-items: center;
      i {
        font-size: 40px;
        color: #4e93fb;
      }
      .inco_txt {
        display: inline-block;
        width: 20px;
        margin-top: 15px;
      }
    }
    .automatic_label_one_center {
      width: 78%;
      margin-left: 20px;
      .automatic_label_one_center_top {
        border: 1px solid #c4c4c4;
        box-sizing: border-box;
        // min-width: 1200px;
        width: 100%;
        padding: 20px;
        .textarea_flex {
          display: flex;
          .textarea_input {
            margin-top: 10px;
            width: 600px;
            height: 250px;
            font-size: 14px;
            color: #000000;
            line-height: 22px;
          }
          .right_back {
            padding: 5px 10px;
            background: #fbfbfb;
            color: #c1c1c1;
            height: 240px;
            overflow: auto;
          }
        }
      }
      .blue_title {
        padding-bottom: 20px;
        color: #146aff;
        font-size: 20px;
        border-bottom: 1px solid #efefef;
        .title_trips {
          color: #999999;
          font-size: 14px;
        }
      }
      .new_label {
        margin-top: 10px;
        width: 256px;
        height: 46px;
        line-height: 46px;
        border-radius: 2px;
        color: #fff;
        text-align: center;
        background: #4e93fb;
        cursor: pointer;
      }
    }
  }
  .automatic_label_two {
    display: flex;
    margin-top: 20px;
    .automatic_label_one_left {
      padding-top: 30px;
      height: 385px;
      width: 71px;
      background: #f6f6f6;
      display: flex;
      flex-direction: column;
      align-items: center;
      i {
        font-size: 40px;
        color: #4e93fb;
      }
      .inco_txt {
        display: inline-block;
        width: 20px;
        margin-top: 15px;
      }
    }
    .automatic_label_one_center {
      margin-left: 20px;
      .automatic_label_one_center_top {
        border: 1px solid #c4c4c4;
        width: 1500px;
        padding: 20px;
        height: 315px;

        .checkbox_tag {
          height: 210px;
          overflow: auto;
        }
        .tag_cuposer {
          margin-right: 10px;
          margin-top: 10px;
          cursor: pointer;
        }
      }
      .blue_title {
        padding-bottom: 20px;
        color: #146aff;
        font-size: 20px;
        border-bottom: 1px solid #efefef;
      }
      .new_label {
        margin-top: 10px;
        width: 256px;
        height: 46px;
        line-height: 46px;
        border-radius: 2px;
        color: #fff;
        text-align: center;
        background: #4e93fb;
        cursor: pointer;
      }
    }
    .group_check_flex {
      display: flex;
      align-items: center;
      position: relative;
      top: 10px;
    }
  }
  .automatic_label_one_right {
    border: 1px solid #c4c4c4;
    // width: 236px;
    height: 300px;
    padding: 20px;
    margin-left: 20px;
    width: 20%;
    .tag_center {
      margin-top: 10px;
      height: 260px;
      overflow: auto;
      margin-bottom: 10px;
    }
    .tag_flex {
      border-bottom: 1px solid #efefef;
      padding-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .tag_group {
        color: #146aff;
      }
    }
  }
}

.storeCenter_item_top {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  padding-bottom: 14px;
  position: relative;
  background: #ffffff;
  padding: 16px 20px 10px 0px;
  .storeCenter_item_top_type {
    position: absolute;
    width: 93px;
    height: 34px;
    background: #ff7d18;
    color: #fff;
    text-align: center;
    line-height: 34px;
    border-radius: 10px 10px 10px 0px;
    top: 0px;
    left: 0px;
  }
  .storeCenter_item_top_left {
    display: flex;
    flex-direction: row;

    img {
      width: 100px;
      height: 80px;
      border-radius: 2px;
      margin-right: 14px;
    }

    .storeCenter_item_top_left_flex {
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .img_commer {
        display: flex;
        border: 1px solid #146aff;
        border-radius: 39px;
        .name {
          font-size: 12px;
          line-height: 20px;
          color: #146aff;
        }
        .color_img {
          background: #146aff;
          height: 20px;
          width: 25px;
          border-radius: 20px;
          position: relative;
          left: -10px;
          img {
            width: 13px;
            height: 13px;
            position: relative;
            left: 5px;
            top: 2px;
          }
        }
      }
      .img_commers {
        display: flex;
        border: 1px solid #ff9c00;
        border-radius: 39px;
        margin: 0px 10px;
        .name {
          font-size: 12px;
          line-height: 20px;
          color: #ff9c00;
        }
        .color_img {
          background: #ff9c00;
          height: 20px;
          width: 25px;
          border-radius: 20px;
          position: relative;
          left: -10px;
          img {
            width: 13px;
            height: 13px;
            position: relative;
            left: 5px;
            top: 2px;
          }
        }
      }

      h4 {
        color: #1f2d3d;
        font-size: 26px;
        font-weight: 500;
        margin: 0;
      }

      .address {
        font-size: 14px;
      }
    }
  }
}

.new_page {
  margin: 20px auto;
  width: 100%;
  text-align: center;
}

.keywordVersion {
  width: 1510px;
  height: 99px;
  margin: 14px 0 14px 100px;
  padding: 10px;
  border-radius: 4px 0px 0px 0px;
  border: 1px solid #efefef;
  overflow: hidden;
  .title {
    color: #146aff;
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 600;
    line-height: 19.6px;
    text-align: left;
  }
  .time {
    padding-left: 10px;
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    text-align: left;
    color: #999;
  }
  .bom {
    margin-top: 20px;
    color: #4e93fb;
  }
  .el-icon-refresh {
    font-size: 16px;
  }
}
// 控制按钮置灰
.disabled {
  color: #fff;
  background-color: #a0cfff;
  border-color: #a0cfff;
}
.bom_other {
  width: 96% !important;
  .automatic_label_one_center_top {
    width: 98% !important;
  }
}
</style>

<template>
  <div>
    <div class="index extrafile">
      <div style="margin-bottom: 10px">
        <el-button type="primary" @click="dialogFormVisible = true"
          >+添加附件</el-button
        >
      </div>
      <el-table
        class="ly_table"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{
          background: '#F5F6F7',
          color: '#363E4D',
          'text-align': 'center',
        }"
        :data="form2"
      >
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset />
        </template>
        <el-table-column
          align="center"
          prop="fileName"
          label="附件名称"
          :formatter="filteryear"
        ></el-table-column>
        <el-table-column label="操作" width="150" align="center">
          <template slot-scope="scope">
            <button-table
              content="下载"
              @buttonClick="downloadFile(scope.row)"
              icon="jr-iconjr-icon-download"
            />
            <button-table
              content="查看"
              icon="jr-iconjr-icon-eye"
              @buttonClick="seeClick(scope.row, scope.$index)"
            />
            <!-- <button-table
            @buttonClick="openEdit(scope.row)"
            content="编辑"
            icon="jr-iconjr-icon-edit"
            />-->
            <button-table
              @buttonClick="deleteEx(scope.row, scope.$index)"
              content="删除"
              icon="jr-iconjr-icon-delect"
            />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[3, 5, 10]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
      <el-dialog
        title="添加附件"
        :visible.sync="dialogFormVisible"
        center
        @close="handleClose"
      >
        <el-form :model="form" :rules="formRules" ref="ruleForm">
          <el-form-item label="附件上传：">
            <div class="brn_input">
              <el-upload
                action="'"
                ref="my-upload"
                :before-upload="beforeAvatarUpload"
                :http-request="upload"
                :multiple="false"
                accept=".pdf"
              >
                <el-button type="info">上传文件</el-button>
              </el-upload>
            </div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="addPersonEx()">确 定</el-button>
          <el-button @click="dialogFormVisible = false">取 消</el-button>
        </div>
      </el-dialog>
      <el-dialog title="编辑附件" :visible.sync="dialogFormVisible1" center>
        <el-form :model="editform" :rules="formRules" ref="editruleForm">
          <el-form-item label="语言:" label-width="120px" prop="languageName">
            <el-input
              v-model="editform.fileName"
              style="width: 430px"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="editEx('editruleForm')"
            >确 定</el-button
          >
          <el-button @click="cancelEx('editruleForm')">取 消</el-button>
        </div>
      </el-dialog>

      <el-dialog
        title="预览文件"
        :visible.sync="isViewPdf20"
        :fullscreen="true"
      >
        <iframe
          :src="fileUrl"
          frameborder="0"
          style="width: 98vw; height: 90vh"
        ></iframe>
      </el-dialog>
    </div>
    <!-- <div class="bottom" v-if="personType">
      <el-button @click="cancelItem" type="primary">确定</el-button>
      <el-button type="info" @click="cancelItem">取消</el-button>
    </div>-->
  </div>
</template>

<script>
import ButtonTable from "../../../../components/ButtonTable.vue";
import { addFile, getFile, deleteFile, getFileOne } from "@/api/talent.js";
import { pagination } from "../../../../mixins/pagination";
import { uploadFile } from "@/api/common";
import axios from "axios";
import { URL } from "../../../../../config";
export default {
  mixins: [pagination],
  components: {
    ButtonTable,
    uploadFile,
  },
  data() {
    return {
      form: {
        fileName: [], //附件名称
        url: [], //地址
        personnelCode: "",
      },
      num: [],
      formRules: {},
      queryInfo: {
        // personCode: "", //人才编号
        pageSize: 10,
        pageNum: 1,
      },
      // total: 0,
      form2: [],
      dialogFormVisible: false,
      dialogFormVisible1: false,
      editform: "",

      enclosureList: [], //产品附件表格数据
      dialogVisibleType: 0,
      multipleTable: [],
      dataForm: {
        productCode: "",
        productFileIntro: "",
        productFileKey: "",
        fileString: "", //文件上传数组
      },
      fileList: [], //编辑附件回显
      isViewPdf20: false,
      fileUrl: "",
      fileKey: [],
    };
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem("token");
    },
  },
  props: {
    // 编辑数据
    personData: {
      type: Object,
    },
    // 0 新增 1 编辑
    personType: {
      type: Number,
    },
    // 新增唯一id
    saveId: {
      type: String,
    },
  },
  created() {
    if (this.personType || this.saveId) {
      this.search();
    }
  },
  methods: {
    // 清空表单
    deleteForm() {
      this.form = {
        fileName: "", //语言
        url: "", //能力
      };
      this.dialogFormVisible = false;
      this.dialogFormVisible1 = false;
    },
    // 查询
    async search() {
      if (this.personType || this.saveId) {
        this.queryInfo.personnelCode = this.personData.personCode
          ? this.personData.personCode
          : this.saveId;
      }
      const res = await getFile(this.queryInfo);
      if (res.code === 200) {
        this.form2 = res.data.list;
      }
    },
    // 添加工作经历
    async addPersonEx() {
      if (this.personType || this.saveId) {
        Object.assign(this.form, {
          Authorization: this.Authorization,
          personnelCode: this.personData.personCode
            ? this.personData.personCode
            : this.saveId,
          personType: "学术机构人才",
        });
        const res = await addFile(this.form);
        this.search();
        this.dialogFormVisible = false;
      } else {
        this.dialogFormVisible = false;
      }
    },
    // 删除工作经历
    async deleteEx(data, index) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示").then(
        async () => {
          if (this.personType || this.saveId) {
            const deleteForm = {
              id: data.id,
              personCode: this.personData.personCode
                ? this.personData.personCode
                : this.saveId,
              personType: "学术机构人才",
            };
            const res = await deleteFile(deleteForm);
            if (res.code == 200) {
              this.search();
            }
          } else {
            this.form2.splice(index, 1);
          }
        }
      );
    },
    // 打开编辑界面
    openEdit(data) {
      this.dialogFormVisible1 = true;
      this.editform = JSON.parse(JSON.stringify(data));
      Object.assign(this.editform, {
        Authorization: this.Authorization,
      });
    },
    // 文件上传
    //限制上传类型
    beforeAvatarUpload(file) {
      let fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = fileName === "xls";
      const extension2 = fileName === "xlsx";
      const extension3 = fileName === "pdf";
      if (!extension && !extension2 && !extension3) {
        this.$message({
          message: "上传文件只能是pdf、xls、xlsx格式!",
          type: "warning",
        });
        this.clearFiles();
        this.dataForm.fileString = "";
      }
    },
    //文件上传接口
    async upload(params) {
      const form = new FormData();
      form.append("files", params.file);
      const res = await uploadFile(form);
      if (res.code == 200) {
        this.form.fileName = res.data.fileAddr[0];
        this.form.url = res.data.fileLook[0];
      }
      this.form2.push({
        name: params.file.name,
        fileName: res.data.fileAddr[0],
        url: res.data.fileLook[0],
      });
    },
    //查看单个附件
    seeClick(row, index) {
      this.fileUrl = row.url;
      window.open(this.fileUrl);
      // this.isViewPdf20 = true
    },
    // 下载
    async downloadFile(row) {
      // const URL = "http://192.168.0.12:8085/";
      const url = row.fileName;
      axios({
        method: "GET",
        url: `${URL}/public/downloadUrlFile`,
        params: { urlStr: url },
        responseType: "blob",
        headers: {
          // 设置请求头
          Authorization: this.Authorization,
        },
      })
        .then((res) => {
          let blob = new Blob([res.data], {
            type: `application/msword`,
          });
          let href = window.URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
          const a = document.createElement("a"); //创建a标签
          a.style.display = "none";
          a.href = href; // 指定下载'链接
          a.download = row.fileName.substring(
            row.fileName.lastIndexOf("/") + 1
          ); //指定下载文件名
          a.click(); //触发下载
          URL.revokeObjectURL(a.href); //释放URL对象
          document.body.removeChild(a); //释放标签
        })
        .catch((err) => {
          //this.$message.error('文件下载失败')
        });
    },
    handleClose() {
      this.form = {
        fileName: "", //语言
        url: "", //能力
      };
    },
    cancelItem() {
      this.$router.go(-1);
    },
    filteryear(val) {
      // 线上
      let gstime = val.fileName.replace("elite-all/", " ");
      // 线下
      // let gstime = val.fileName.replace("test/", " ");
      return gstime;
    },
  },
  mounted() {
    if (this.personType) {
      document.getElementsByClassName("extrafile")[0].style.height =
        document.body.clientHeight - 320 + "px";
    }
  },
};
</script>

<style scoped lang="less">
.el-input__inner {
  width: 200px;
}
.el-button {
  padding: 12px 15px;
}
.index {
  // height: 560px;
  width: 100%;
  overflow: hidden;
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    padding: 8px 10px;
    margin: 0 20px;
  }
}
</style>

<template>
  <div>
    <div class="index paperOne">
      <div style="margin-bottom: 10px">
        <el-button type="primary" @click="dialogFormVisible = true"
          >+添加论文</el-button
        >
      </div>
      <el-table
        class="ly_table"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{
          background: '#F5F6F7',
          color: '#363E4D',
          'text-align': 'center',
        }"
        :data="form1"
      >
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset />
        </template>
        <el-table-column prop="thesisName" label="名称"></el-table-column>
        <el-table-column
          prop="name"
          label="论文合作者"
          v-if="personType"
        ></el-table-column>
        <el-table-column prop="name" label="论文合作者" v-else>
          <template slot-scope="scope">
            <span v-for="(item, index) in scope.row.name" :key="index"
              >{{ item }}&nbsp;&nbsp;</span
            >
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120" align="center">
          <template slot-scope="scope">
            <button-table
              @buttonClick="openEdit(scope.row)"
              content="编辑"
              icon="jr-iconjr-icon-edit"
            />
            <button-table
              @buttonClick="deleteEx(scope.row)"
              content="删除"
              icon="jr-iconjr-icon-delect"
            />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[3, 5, 10]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
      <el-dialog
        title="添加论文"
        :visible.sync="dialogFormVisible"
        center
        @close="handleClose"
      >
        <el-form
          :model="form"
          ref="form"
          label-width="100px"
          class="demo-dynamic"
        >
          <el-form-item
            prop="thesisName"
            label="论文名称"
            :rules="[
              { required: true, message: '请输入论文名称', trigger: 'blur' },
            ]"
          >
            <el-input v-model="form.thesisName"></el-input>
          </el-form-item>
          <el-form-item
            v-for="(item, index) in form.name"
            label="论文合作者"
            :key="item.key"
            :prop="'name.' + index + '.val'"
            :rules="{
              required: true,
              message: '论文合作者不能为空',
              trigger: 'blur',
            }"
          >
            <div style="display: flex">
              <el-input v-model="item.val"></el-input>
              <div @click.prevent="removeDomain(item)">
                <button-table content="删除" icon="jr-iconjr-icon-clean" />
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('form')"
              >提交</el-button
            >
            <el-button @click="addDomain">添加论文合作者</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-dialog
        title="编辑论文"
        :visible.sync="dialogFormVisible1"
        @close="handleClose"
      >
        <el-form
          :model="form"
          ref="form"
          label-width="100px"
          class="demo-dynamic"
        >
          <el-form-item
            prop="thesisName"
            label="论文名称"
            :rules="[
              { required: true, message: '请输入论文名称', trigger: 'blur' },
            ]"
          >
            <el-input v-model="form.thesisName"></el-input>
          </el-form-item>
          <el-form-item
            v-for="(item, index) in form.name"
            label="论文合作者"
            :key="item.key"
            :prop="'name.' + index + '.val'"
            :rules="{
              required: true,
              message: '论文合作者不能为空',
              trigger: 'blur',
            }"
          >
            <div style="display: flex">
              <el-input v-model="item.val"></el-input>
              <div @click.prevent="removeDomain(item)">
                <button-table content="删除" icon="jr-iconjr-icon-clean" />
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm1('form')"
              >提交</el-button
            >
            <el-button @click="addDomain">添加论文合作者</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
    <!-- <div class="bottom" v-if="personType">
        <el-button @click="cancelItem" type="primary">确定</el-button>
        <el-button type="info" @click="cancelItem">取消</el-button>
    </div>-->
  </div>
</template>

<script>
import ButtonTable from "../../../../components/ButtonTable.vue";
import { getPaper, addPaper, editPaper, deletePaper } from "@/api/talent.js";
import { pagination } from "../../../../mixins/pagination";
export default {
  mixins: [pagination],
  components: {
    ButtonTable,
  },
  data() {
    return {
      index: 0,
      form: {
        thesisName: "", //论文名称
        name: [{ val: "" }], //姓名
        createName: "", //创建人名字
        createTime: "", //创建时间
        updateName: "", //修改人名字
        updateTime: "", //修改时间
      },
      form1: [],
      queryInfo: {
        personCode: "", //人才编号
        pageSize: 10,
        pageNum: 1,
      },
      // total: 0,
      dialogFormVisible: false,
      dialogFormVisible1: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      editform: "",
    };
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem("token");
    },
  },
  props: {
    // 编辑数据
    personData: {
      type: Object,
    },
    // 0 新增 1 编辑
    personType: {
      type: Number,
    },
    // 新增唯一id
    saveId: {
      type: String,
    },
  },
  created() {
    if (this.personType || this.saveId) {
      this.search();
    }
  },
  methods: {
    async search() {
      if (this.personType || this.saveId) {
        this.queryInfo.personCode = this.personData.personCode
          ? this.personData.personCode
          : this.saveId;
      }
      const res = await getPaper(this.queryInfo);
      if (res.code === 200) {
        this.form1 = res.data.list;
        this.total = res.data.total;
      }
    },
    delete() {
      this.form = {
        thesisName: "", //论文名称
        name: [{ val: "" }], //姓名
        createName: "", //创建人名字
        createTime: "", //创建时间
        updateName: "", //修改人名字
        updateTime: "", //修改时间
      };
      this.dialogFormVisible = false;
      this.dialogFormVisible1 = false;
    },
    // 添加论文
    submitForm(formName) {
      if (this.personType || this.saveId) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            Object.assign(this.form, {
              Authorization: this.Authorization,
              personCode: this.personData.personCode
                ? this.personData.personCode
                : this.saveId,
              personType: "学术人才机构",
            });
            this.form.name = this.form.name.map((item) => {
              return item.val;
            });
            const res = await addPaper(this.form);
            this.search();
            this.delete();
          }
        });
      } else {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.form.name = this.form.name.map((item) => {
              return item.val;
            });
            this.form1.push(this.form);
            this.delete();
          }
        });
      }
    },
    // 修改论文
    submitForm1(formName) {
      if (this.personType || this.saveId) {
        Object.assign(this.form, {
          Authorization: this.Authorization,
          personCode: this.personData.personCode
            ? this.personData.personCode
            : this.saveId,
        });
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            this.form.name = this.form.name.map((item) => {
              return item.val;
            });
            const res = await editPaper(this.form);
            this.search();
            this.delete();
          }
        });
      } else {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.form.name = this.form.name.map((item) => {
              return item.val;
            });
            this.form1[this.index] = this.form;
            this.delete();
          }
        });
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    removeDomain(item) {
      var index = this.form.name.indexOf(item);
      if (index !== -1) {
        this.form.name.splice(index, 1);
      }
    },
    addDomain() {
      this.form.name.push({
        val: "",
      });
    },
    openEdit(data) {
      this.index = this.form1.indexOf(data);
      this.form = JSON.parse(JSON.stringify(data));
      if (this.personType || this.saveId) {
        this.form.name = this.form.name.split(",");
      }
      this.dialogFormVisible1 = true;
      this.form.name = this.form.name.map((item) => {
        return { val: item };
      });
    },
    async deleteEx(data) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示").then(
        async () => {
          if (this.personType || this.saveId) {
            const deleteForm = {
              id: data.id,
              personCode: data.personCode,
              personType: "学术机构人才",
            };
            const res = await deletePaper(deleteForm);
            this.search();
          } else {
            let index = this.form1.indexOf(data);
            if (index !== -1) {
              this.form1.splice(index, 1);
            }
          }
        }
      );
    },
    handleClose() {
      this.delete();
    },
    cancelItem() {
      this.$router.go(-1);
    },
  },
  mounted() {
    if (this.personType) {
      document.getElementsByClassName("paperOne")[0].style.height =
        document.body.clientHeight - 320 + "px";
    }
  },
};
</script>

<style scoped lang="less">
.el-input__inner {
  width: 200px;
}
/deep/ .el-button {
  padding: 12px 15px;
}
/deep/.el-dialog {
  width: 645px;
  min-height: 300px;
  padding-bottom: 50px;
}
/deep/ .jr-iconjr-icon-clean {
  font-size: 25px;
}
.index {
  // height: 560px;
  overflow: auto;
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    padding: 8px 10px;
    margin: 0 20px;
  }
}
/deep/ .buttonTable {
  .el-button {
    padding: 7px 5px !important;
  }
}
</style>

<template>
  <div>
    <div class="personMessage">
      <el-form
        :model="editTalentInfo"
        ref="editTalentInfo"
        :rules="ruleFormrules"
        label-width="70px"
        v-if="isShow"
      >
        <el-row style="margin-top: 15px">
          <el-col :span="4">
            <el-form-item label-width="100px" label="性别:" prop="sex">
              <el-radio-group v-model="editTalentInfo.sex">
                <el-radio label="男">男</el-radio>
                <el-radio label="女">女</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label-width="100px" label="年龄:" prop="age">
              <el-input
                v-model="editTalentInfo.age"
                style="width: 205px"
                onkeyup="value=value.replace(/[^\d]/g,'')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-form-item
            label-width="100px"
            label="人才序号:"
            prop="serialNumber"
          >
            <el-input
              v-model="editTalentInfo.serialNumber"
              style="width: 159px"
            ></el-input>
            </el-form-item
        > -->
        <el-form-item label="个人链接:" prop="personUrl" label-width="100px">
          <el-input
            v-model="editTalentInfo.personUrl"
            style="width: 470px"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="工作经验:"
          prop="workingYears"
          style="margin-bottom: 20px"
          label-width="100px"
        >
          <dic-radio-group
            :code.sync="editTalentInfo.workingYears"
            type-code="005"
          />
        </el-form-item>
        <el-form-item
          label="最高学历:"
          label-width="100px"
          prop="educationalBackground"
        >
          <el-select
            v-model="editTalentInfo.educationalBackground"
            placeholder="请选择学历"
            :clearable="true"
          >
            <el-option label="中专" value="中专"></el-option>
            <el-option label="大专" value="大专"></el-option>
            <el-option label="本科" value="本科"></el-option>
            <el-option label="研究生" value="研究生"></el-option>
            <el-option label="硕士" value="硕士"></el-option>
            <el-option label="博士" value="博士"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="合作状态:"
          label-width="100px"
          prop="cooperationStatus"
        >
          <dic-radio-group
            :code.sync="editTalentInfo.cooperationStatus"
            type-code="001"
          />
        </el-form-item>
        <el-form-item label="所在地区:" label-width="100px">
          <cascader-area
            :country="editTalentInfo.personCountry"
            :province="editTalentInfo.personProvince"
            :city="editTalentInfo.personCity"
            @searchCountry="searchCountry"
            @searchProvince="searchProvince"
            @searchCity="searchCity"
          />
        </el-form-item>
        <el-form-item label="职能类型:" prop="data" label-width="100px">
          <dic-checkbox-group
            :code.sync="editTalentInfo.positionType"
            type-code="006"
          />
        </el-form-item>
        <el-form-item label="学位信息:" prop="xuelimessage" label-width="100px">
          <el-input
            v-model="editTalentInfo.degree"
            style="width: 465px"
          ></el-input>
        </el-form-item>
        <el-form-item label="单位名称:" prop="companyName" label-width="100px">
          <el-input
            v-model="editTalentInfo.currentCompany"
            style="width: 465px"
          ></el-input>
          <!-- <el-select
            v-model="editTalentInfo.companyName"
            filterable
            style="width: 470px"
            placeholder="请输入"
          >
            <el-option
              v-for="(item, index) in restaurants"
              :key="index"
              :label="item"
              :value="item"
            />
          </el-select> -->
        </el-form-item>
        <el-form-item
          label="个人页面网站:"
          prop="personalWebsite"
          label-width="100px"
        >
          <!-- <el-input
            v-model="editTalentInfo.personalWebsite"
            style="width: 465px"
          ></el-input> -->
          <el-link
            :href="editTalentInfo.personalWebsite"
            target="_blank"
            :underline="false"
            >{{ editTalentInfo.personalWebsite }}</el-link
          >
          <i
            class="jr-iconjr-icon-edit1"
            style="margin-left: 24px"
            @click="isLinkShow = true"
          ></i>
          <div class="input_button" v-if="isLinkShow">
            <el-input
              v-model="editTalentInfo.personalWebsite"
              style="width: 470px"
            />
            <el-button
              type="primary"
              style="height: 40px; margin-left: 24px"
              @click="isLinkShow = false"
              >保存</el-button
            >
          </div>
        </el-form-item>
        <el-row>
          <el-col :span="6">
            <el-form-item
              label="论文引用数:"
              prop="numberCitations"
              label-width="100px"
            >
              <el-input
                v-model="editTalentInfo.numberCitations"
                style="width: 195px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              label="论文数:"
              prop="publications"
              label-width="100px"
            >
              <el-input
                v-model="editTalentInfo.publications"
                style="width: 200px"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-form-item
          label="技术类别:"
          prop="currentTechId"
          label-width="100px"
        >
          <dic-checkbox-group-talent
            :code.sync="editTalentInfo.currentTechId"
            tag="目前技术"
            @updateCode="updateCode"
          />
        </el-form-item> -->
        <!-- <el-form-item label="技术标签:" label-width="100px" v-if="personType">
          <el-input
            v-model="editTalentInfo.aiTag"
            style="width: 60%"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 8 }"
          ></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="领域类别:" prop="fieldId" label-width="100px">
        <dic-checkbox-group-talent
          :code.sync="editTalentInfo.fieldId"
          tag="领域"
          @updateCode="updateCode"
        />
      </el-form-item> -->
        <el-form-item class="avatar" label="关系图:" label-width="100px">
          <upload-image
            :imageUrl.sync="editTalentInfo.diagrams"
            :saveUrl.sync="editTalentInfo.diagram"
            v-if="personType"
          />
          <upload-image :saveUrl.sync="editTalentInfo.diagram" v-else />
        </el-form-item>
        <el-form-item
          label="勿推荐公司:"
          prop="unidealCompany"
          label-width="100px"
        >
          <el-input
            v-model="editTalentInfo.unidealCompany"
            :autosize="{ minRows: 4, maxRows: 20 }"
            style="width: 60%"
            type="textarea"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="关键词：" v-if="personType" label-width="100px">
          <el-input
            v-model="editTalentInfo.antistop"
            style="width: 60%"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 8 }"
          ></el-input>
        </el-form-item> -->
      </el-form>
    </div>
    <!-- <div v-if="personType" class="bottom">
      <el-button @click="saveTalents()" type="primary">确定</el-button>
      <el-button type="info" @click="cancelItem">取消</el-button>
    </div> -->
  </div>
</template>

<script>
import { updateTalents, addTalents, samrtFind } from "@/api/talent";
import UploadImage from "../../../../components/UploadImage.vue";
import DicCheckboxGroupTalent from "@/components/DicCheckboxGroupTalent.vue";
import DicCheckboxGroup from "@/components/DicCheckboxGroup.vue";
import DicRadioGroup from "@/components/DicRadioGroup.vue";
import CascaderArea from "@/components/CascaderArea.vue";
export default {
  components: {
    UploadImage,
    CascaderArea,
    DicRadioGroup,
    DicCheckboxGroupTalent,
    DicCheckboxGroup,
  },
  watch: {
    "editTalentInfo.personCode": {
      handler(newVal) {
        if (this.editTalentInfo && newVal) {
          this.isShow = true;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    // setTimeout(() => {
    //   if (this.personType && this.editTalentInfo.personCode) {
    //     this.isShow = true
    //   } else if (!this.personType) {
    //     this.isShow = true
    //   }
    // }, 1000)
    if (!this.personType) {
      this.isShow = true;
    }
  },
  data() {
    return {
      isLinkShow: false,
      restaurants: [],
      isShow: false,
      imageUrl: "",
      ruleFormrules: {
        name: [{ required: true, message: "请填写姓名", trigger: "change" }],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        age: [{ required: true, message: "请填写年龄", trigger: "change" }],
        // region: [{ required: true, message: "请选择所在城市", trigger: "change" },],
        workingYears: [
          { required: true, message: "请选择工作经验", trigger: "change" },
        ],
        educationalBackground: [
          { required: true, message: "请选择学历", trigger: "change" },
        ],
        // serialNumber:[
        //   { required: true, message: '请填写人才编号', trigger: 'change' },
        // ],
      },
    };
  },
  props: {
    editTalentInfo: {
      type: Object,
    },
    personType: {
      type: Number,
    },
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem("token");
    },
  },
  methods: {
    // 保存企业人才信息
    async saveTalents() {
      Object.assign(this.editTalentInfo, {
        Authorization: this.Authorization,
      });
      // this.editTalentInfo.currentTechId =
      //   this.editTalentInfo.currentTechId.concat(
      //     this.editTalentInfo.currentTechChildrenId
      //   );
      this.editTalentInfo.fieldId = this.editTalentInfo.fieldId.concat(
        this.editTalentInfo.fieldChildrenId
      );
      if (this.editTalentInfo.numberCitations !== "") {
        this.editTalentInfo.numberCitations =
          this.editTalentInfo.numberCitations * 1;
      }
      if (this.editTalentInfo.publications !== "") {
        this.editTalentInfo.publications = this.editTalentInfo.publications * 1;
      }

      if (
        this.editTalentInfo.fieldId[0] == null ||
        this.editTalentInfo.fieldId[0] == "null"
      ) {
        this.editTalentInfo.fieldId = [];
      }
      if (
        this.editTalentInfo.expectedTechId[0] == null ||
        this.editTalentInfo.expectedTechId[0] == "null"
      ) {
        this.editTalentInfo.expectedTechId = [];
      }
      // if (
      //   this.editTalentInfo.currentTechId[0] == null ||
      //   this.editTalentInfo.currentTechId[0] == "null"
      // ) {
      //   this.editTalentInfo.currentTechId = [];
      // }
      const res = await updateTalents(this.editTalentInfo);
      if (res.code === 200) {
        this.$message.success("编辑成功");
        this.$router.go(-1);
      } else {
        this.$message.error("编辑失败");
      }
    },
    change() {
      if (this.editTalentInfo) {
        this.ruleForm = this.editTalentInfo;
        this.ruleForm.expectedFieldId =
          this.ruleForm.expectedFieldId.split(",");
        // this.ruleForm.currentTechId = this.ruleForm.currentTechId.split(",");
        this.ruleForm.fieldId = this.ruleForm.fieldId.split(",");
        this.ruleForm.expectedTechId = this.ruleForm.expectedTechId.split(",");
      }
    },
    cancelItem() {
      this.$router.go(-1);
    },
    searchCountry(val) {
      this.editTalentInfo.personCountry = val;
    },
    searchProvince(val) {
      this.editTalentInfo.personProvince = val;
    },
    searchCity(val) {
      if (val != "北京" && val != "天津" && val != "上海" && val != "重庆") {
        this.editTalentInfo.personCity = val;
      }
    },
    updateCode(val, tag) {
      switch (tag) {
        case "领域":
          this.editTalentInfo.fieldChildrenId = val;
          break;

        case "目前技术":
          this.editTalentInfo.currentTechChildrenId = val;
          break;

        case "期望技术":
          this.editTalentInfo.expectedTechChildrenId = val;
          break;

        default:
          break;
      }
    },

    // 单位名称弹出框
    // async getCompanyList(){
    //   const res = await samrtFind();
    //   this.restaurants = res.data
    // }
  },
  mounted() {
    if (this.personType) {
      document.getElementsByClassName("personMessage")[0].style.minHeight =
        document.body.clientHeight - 380 + "px";
    }
  },
};
</script>

<style lang="less" scoped>
.personMessage {
  // 蓝色圆角化
  margin-bottom: 6px;
  // height: 560px;
  overflow: auto;
  .topTip {
    border-bottom: 1px solid #dbdbdb;
    margin-bottom: 5px;
    .yuanjiao {
      border: 2px solid #1e9fff;
      height: 20px;
      background-color: #1e9fff;
      border-radius: 5px;
    }
    .basicMessage {
      display: inline-block;
      margin-left: 10px;
      margin-bottom: 9px;
    }
  }
  .el-select {
    input::-webkit-input-placeholder {
      color: #1f212b;
    }
    //input::-moz-input-placeholder {
    //  color: #1f212b;
    //}
    input::-moz-placeholder {
      color: #1f212b;
    }
    input::-ms-input-placeholder {
      color: #1f212b;
    }
    margin-right: 8px;
  }
  .el-button {
    padding: 12px 38px;
  }
  .el-form-item {
    margin: 20px 0px 10px 8px;
  }
  /deep/ .el-textarea__inner {
    height: 100%;
  }

  // 头像设置
  .avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 14px;
    color: #8c939d;
    width: 76px;
    height: 76px;
    border: 1px dashed #d9d9d9;
    line-height: 76px;
    text-align: center;
  }
  .avatar-uploader-icon1 {
    font-size: 14px;
    color: #8c939d;
    width: 160px;
    height: 90px;
    border: 1px solid #d9d9d9;
    line-height: 90px;
    text-align: center;
  }
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}
</style>

<template>
  <div>
    <div class="company" v-if="personType">
      <div class="company_left">
        <upload-image
          :imageUrl.sync="enterTalentsInfo.headPortraits"
          :saveUrl.sync="enterTalentsInfo.headPortrait"
        />
        <div class="info">
          <div>
            <div class="fullName" v-if="!isShowInput">
              <div class="companyFullName">{{ enterTalentsInfo.name }}</div>
              <i class="jr-iconjr-icon-edit1" @click="editClick" />
            </div>
            <div class="fullName" v-else>
              <el-input v-model="enterTalentsInfo.name" />
              <el-button type="primary" @click="sureInfo">保 存</el-button>
            </div>
          </div>
          <div class="updateTime">
            更新时间：{{ enterTalentsInfo.updateTime }}
          </div>
        </div>
      </div>
      <div class="company_right">
        <div>创建人：{{ enterTalentsInfo.createName }}</div>
        <div>修改人：{{ enterTalentsInfo.updateName }}</div>
        <div>收录时间：{{ enterTalentsInfo.createTime }}</div>
      </div>
    </div>
    <el-form
      v-else
      :model="enterTalentsInfo"
      :inline="true"
      :rules="rulesTalentsInfo"
      ref="rulesTalentsInfo"
      class="companyInfo"
    >
      <el-form-item class="avatar">
        <upload-image :saveUrl.sync="enterTalentsInfo.headPortrait" />
      </el-form-item>
      <el-form-item label="姓名" prop="name">
        <el-input v-model="enterTalentsInfo.name" class="lager-input" />
      </el-form-item>
      <!-- <el-form-item>
        <el-button type="primary" @click="saveCompanyInfo">保存</el-button>
      </el-form-item>-->
    </el-form>
    <el-card>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="个人信息" name="first">
          <person-message
            :editTalentInfo="enterTalentsInfo"
            :personType="personType"
            ref="personMessage"
          ></person-message>
        </el-tab-pane>
        <el-tab-pane label="联系方式" name="second">
          <contact-us
            :editTalentInfo.sync="enterTalentsInfo"
            :personData="personData"
            :personType="personType"
            ref="contactInfo"
          ></contact-us>
        </el-tab-pane>
        <el-tab-pane label="工作经历" name="third">
          <work-history
            :personData="personData"
            :personType="personType"
            :saveId="saveId"
            ref="workHistory"
          ></work-history>
        </el-tab-pane>
        <el-tab-pane label="论文" name="fourth">
          <the-paper
            :personData="personData"
            :personType="personType"
            :saveId="saveId"
            ref="thePaper"
          ></the-paper>
        </el-tab-pane>
        <el-tab-pane label="专利" name="fifth">
          <the-patent
            :personData="personData"
            :personType="personType"
            :saveId="saveId"
            ref="thePatent"
          ></the-patent>
        </el-tab-pane>
        <el-tab-pane label="科研项目" name="sixth">
          <research-project
            :personData="personData"
            :personType="personType"
            :saveId="saveId"
            ref="researchProject"
          ></research-project>
        </el-tab-pane>
        <el-tab-pane label="附件" name="eighth">
          <extra-file
            :personData="personData"
            :personType="personType"
            :saveId="saveId"
            ref="extraFile"
          ></extra-file>
        </el-tab-pane>
        <el-tab-pane label="其他能力" name="nine">
          <other-abilities
            ref="otherAbilities"
            :personData="personData"
            :personType="personType"
            :saveId="saveId"
          ></other-abilities>
        </el-tab-pane>
        <el-tab-pane label="标签信息" name="ten">
          <labelInfo ref="labelInfo"></labelInfo>
        </el-tab-pane>
        <el-tab-pane label="手动标签" name="eleven">
          <labelManagement
            ref="labelManagement"
            :saveId="String(saveId)"
            :personData="personData"
          ></labelManagement>
        </el-tab-pane>
        <el-tab-pane label="半自动化标签" name="twelve">
          <robotizationlabel
            ref="robotizationlabel"
            :saveId="String(saveId)"
            :personData="personData"
          ></robotizationlabel>
        </el-tab-pane>
      </el-tabs>
    </el-card>
    <div
      style="height: 50px; background: #fff;"
      v-if="this.activeName == 'twelve'"
    ></div>
    <div class="bottom" v-if="this.activeName !== 'twelve'">
      <el-button type="primary" @click="saveItem">保存</el-button>
      <el-button @click="cancelItem">取消</el-button>
    </div>
  </div>
</template>
<script>
import {
  updateTalents,
  addTalents,
  getTalent,
  selectPersonLabelDetail,
  insertPersonLabel,
  updatePersonLabel,
  insertPersonLabelManual,
  updatePersonLabelManual,
  getPersonOther,
  updateOtherPerson,
} from "@/api/talent";
import PersonMessage from "./editMessage/personMessage";
import ContactUs from "./editMessage/contact";
import WorkHistory from "./editMessage/workHistory";
import ThePaper from "./editMessage/thePaper";
import ThePatent from "./editMessage/thePatent";
import ResearchProject from "./editMessage/researchProject";
import ExtraFile from "./editMessage/extraFile";
import UploadImage from "../../../components/UploadImage.vue";
import OtherAbilities from "./editMessage/otherAbilities.vue";
import labelInfo from "./editMessage/labelInfo.vue";
import labelManagement from "./editMessage/labelManagement.vue";
import robotizationlabel from "./editMessage/robotizationlabel.vue";
export default {
  name: "editTalentXS",
  components: {
    PersonMessage,
    ContactUs,
    WorkHistory,
    ThePaper,
    ThePatent,
    ResearchProject,
    ExtraFile,
    UploadImage,
    OtherAbilities,
    labelInfo,
    labelManagement,
    robotizationlabel,
  },
  mounted() {
    if (this.personType) {
      // document.querySelector('.el-tabs__content').style.height =
      // document.body.clientHeight - 310 + 'px'
    } else {
      document.querySelector(".el-tabs__content").style.minHeight =
        document.body.clientHeight - 368 + "px";
    }
  },
  computed: {
    // 当前编辑这行数据
    personData() {
      return JSON.parse(this.$route.query.personData);
    },
    // 0 新增 1 编辑
    personType() {
      return Number(this.$route.query.personType);
    },
    // token
    Authorization() {
      return sessionStorage.getItem("token");
    },
  },
  created() {
    // 编辑就调用查询
    if (this.personType) {
      this.getTalents();
      this.getOther();
      this.selectPersonLabelDetail();
    }
  },
  watch: {
    // 监听是否在手动标签页面
    activeName: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && newVal == "eleven") {
          this.$refs.labelManagement.getBylabelId();
        }
      },
    },
  },
  data() {
    return {
      activeName: "first", // 当前页面
      isShowInput: false,
      personnelId: null, // 新增的数据唯一id
      // 表单校验信息
      enterTalentsInfo: {
        personCode: "",
        degree: "",
        name: "", //姓名
        personType: "学术机构人才",
        age: "", //年龄
        educationalBackground: "", //最高学历
        currentTechId: [], //目前技术类别
        expectedTechId: [], //期望技术类别 1
        headPortrait: "", //头像
        birthday: "", //出生日期
        sex: "", //性别
        personUrl: "", //个人链接
        currentCompany: "", //目前在职企业
        companyName: "", //单位名称
        currentIndustry: "", //所在行业
        desiredIndustry: "", //期望行业
        personCountry: "", //所在国家
        personProvince: "", //所在省份
        personCity: "", //所在城市
        // serialNumber:"",
        expectedLocation: "", //期望地点
        researchInterest: "", //研究兴趣
        personalWebsite: "", //个人网页网站
        position: "", //所在职位
        expectedPosition: "", //期望职务
        cooperationStatus: "", //合作状态
        workingYears: "", //工作经验
        positionType: [], //职能类型
        diagram: "", //关系图
        diagrams: "",
        currentTechChildrenId: [],
        fieldChildrenId: [],
        unidealCompany: "", //勿推荐公司
        numberCitations: null, //论文引用书数
        publications: null, //论文数
        presentSalary: "", //目前薪资
        salaryExpectation: "", //期望薪资
        note: "", //备注
        // technique: "", //我的技能
        selfAssessment: "", //自我评价
        fax: "", //传真
        // certificate: "", //证书
        headPortraits: "",
        // otherOffice: "", //其它任职
        careerOrientation: "", //职业定位
        noted: "", //特别注明
        // personDataIntegrity: "", //数据完整度
        // entryYearTime: '', //入职年份
        // entryMonthTime: '', //入职月份
        fieldId: [], //领域类别
        createName: "", //创建人名字
        createTime: "", //创建时间
        updateName: "", //修改人名字
        updateTime: "", //修改时间
      },
      // 表单校验信息
      rulesTalentsInfo: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
      },
      saveId: "", //新增唯一id
    };
  },
  methods: {
    // 切换tabs页(切换到手动标签,调用查询回显的数据)
    handleClick(tab, event) {
      if (tab.index == 9 && this.saveId) {
        this.$refs.labelManagement.getBylabelId();
      }
    },
    // 查询其他能力
    async getOther() {
      const res = await getPersonOther({
        personCode: this.personData.personCode,
      });
      if (!res.code) {
        if (res) {
          this.$refs.otherAbilities.otherForm = res;
        } else {
          this.$refs.otherAbilities.otherForm = {
            languages: [], //所习语言
            abilityCertificate: [""], //能力证书
            technique: [""], //个人技能
            selfAssessment: "", //自我评价
            certificate: [""], //证书名称
            otherOffice: [""], //其他任职
            careerOrientation: "", //职业定位
            noted: "", //特别注明
            personalFile: "", //附件
          };
        }
      } else {
        this.$refs.otherAbilities.otherForm = {
          languages: [], //所习语言
          abilityCertificate: [""], //能力证书
          technique: [""], //个人技能
          selfAssessment: "", //自我评价
          certificate: [""], //证书名称
          otherOffice: [""], //其他任职
          careerOrientation: "", //职业定位
          noted: "", //特别注明
          personalFile: "", //附件
        };
      }
    },
    // 保存
    async save(res, path) {
      Object.assign(this.enterTalentsInfo, {
        Authorization: this.Authorization,
      });
      let contact = this.$refs.contactInfo.ruleForm; //联系方式的数据
      let workHistory = this.$refs.workHistory.form2; //工作经历
      let thePaper = this.$refs.thePaper.form1; //论文
      let thePatent = this.$refs.thePatent.form2; //专利
      let researchProject = this.$refs.researchProject.form2; //科研项目
      let extraFile = this.$refs.extraFile.form2; // 附件
      let otherForm = this.$refs.otherAbilities.otherForm; //其他能力
      Object.assign(contact, {
        Authorization: this.Authorization,
        personCode: this.saveId ? this.saveId : this.personData.personCode,
      });
      Object.assign(otherForm, {
        Authorization: this.Authorization,
        personCode: this.saveId ? this.saveId : this.personData.personCode,
      });
      if (this.enterTalentsInfo.currentTechChildrenId) {
        this.enterTalentsInfo.currentTechId =
          this.enterTalentsInfo.currentTechId.concat(
            this.enterTalentsInfo.currentTechChildrenId
          );
      }
      if (this.enterTalentsInfo.fieldChildrenId) {
        this.enterTalentsInfo.fieldId = this.enterTalentsInfo.fieldId.concat(
          this.enterTalentsInfo.fieldChildrenId
        );
      }
      if (this.enterTalentsInfo.numberCitations !== null) {
        this.enterTalentsInfo.numberCitations =
          this.enterTalentsInfo.numberCitations * 1;
      }
      if (this.enterTalentsInfo.publications !== null) {
        this.enterTalentsInfo.publications =
          this.enterTalentsInfo.publications * 1;
      }
      if (
        this.enterTalentsInfo.fieldId[0] == null ||
        this.enterTalentsInfo.fieldId[0] == "null"
      ) {
        this.enterTalentsInfo.fieldId = [];
      }
      if (
        this.enterTalentsInfo.expectedTechId[0] == null ||
        this.enterTalentsInfo.expectedTechId[0] == "null"
      ) {
        this.enterTalentsInfo.expectedTechId = [];
      }
      if (
        this.enterTalentsInfo.currentTechId[0] == null ||
        this.enterTalentsInfo.currentTechId[0] == "null"
      ) {
        this.enterTalentsInfo.currentTechId = [];
      }
      const params = {
        personDto: this.enterTalentsInfo, // 个人信息
        personContact: contact, // 联系方式
        peDtos: workHistory, // 工作经历
        prEDtos: [],
        personEducationals: [],
        languages: [],
        additionalInformations: {},
        thesisDtos: thePaper, // 论文
        patents: thePatent, // 专利
        projects: researchProject, // 科研项目
        personFiles: extraFile, // 附件
        personOther: otherForm, //其他能力
      };

      // 新增
      if (!this.personType) {
        if (!this.saveId) {
          if (this.enterTalentsInfo.name == "") {
            this.$message.error("请填写人才名称");
          } else {
            res = await addTalents(params);
          }
        } else {
          // res = await updateTalents(params);

          // 如果不是其他能力就正常调用编辑接口，如果是就调用自己的接口
          // if (this.activeName !== "nine") {
          //   res = await updateTalents(params);
          // } else {
          //   res = await updateOtherPerson(params.personOther);
          // }

          // 如果不是其他能力就正常调用编辑接口，如果是就调用自己的接口
          if (this.activeName !== "nine" && this.activeName !== "second") {
            res = await updateTalents(params.personDto);
          } else if (this.activeName == "nine") {
            res = await updateOtherPerson(params.personOther);
          } else if (this.activeName == "second") {
            this.$refs.contactInfo.submitForm();
            if (this.$refs.contactInfo.activeName) {
              this.activeName = this.$refs.contactInfo.activeName;
            }

            // res = this.$refs.contactInfo.res;
          }
        }
      } else {
        // res = await updateTalents(this.enterTalentsInfo);
        // res = await updateTalents(params);
        if (this.activeName !== "nine" && this.activeName !== "second") {
          res = await updateTalents(params.personDto);
        } else if (this.activeName == "nine") {
          res = await updateOtherPerson(params.personOther);
        } else if (this.activeName == "second") {
          this.$refs.contactInfo.submitForm();
          if (this.$refs.contactInfo.activeName) {
            this.activeName = this.$refs.contactInfo.activeName;
          }
        }
      }
      if (res.code == 200) {
        this.$message.success(`${this.personType ? "编辑" : "新增"}成功`);
        // 赋值新增的唯一id
        if (
          res.msg !== "修改成功" &&
          res.msg !== null &&
          res.msg !== "编辑成功" &&
          res.msg !== "编辑失败"
        ) {
          this.saveId = res.msg;
          this.enterTalentsInfo.personCode = res.msg;
          this.activeName = path;
        } else {
          this.activeName = path;
        }
      } else {
        `${this.personType ? "编辑" : "新增"}失败`;
      }
    },
    // 全部保存
    async saveItem() {
      let res;
      switch (this.activeName) {
        case "first":
          this.$refs.personMessage.$refs.editTalentInfo.validate(
            async (valid) => {
              if (valid) {
                this.save(res, "second");
              }
            }
          );
          break;
        case "second":
          this.$refs.personMessage.$refs.editTalentInfo.validate(
            async (valid) => {
              // 编辑
              if (this.personType || this.saveId) {
                const res = this.$refs.contactInfo.submitForm();
                res.then((rs) => {
                  if (rs == 200) {
                    this.$message.success("修改成功");
                    this.activeName = "third";
                  }
                });
              } else {
                this.save(res, "third");
              }
            }
          );
          break;
        case "third":
          this.$refs.personMessage.$refs.editTalentInfo.validate(
            async (valid) => {
              if (valid) {
                // 编辑
                if (this.personType || this.saveId) {
                  this.activeName = "fourth";
                } else {
                  this.save(res, "fourth");
                }
              }
            }
          );
          break;
        case "fourth":
          this.$refs.personMessage.$refs.editTalentInfo.validate(
            async (valid) => {
              if (valid) {
                // 编辑
                if (this.personType || this.saveId) {
                  this.activeName = "fifth";
                } else {
                  this.save(res, "fifth");
                }
              }
            }
          );
          break;
        case "fifth":
          this.$refs.personMessage.$refs.editTalentInfo.validate(
            async (valid) => {
              if (valid) {
                // 编辑
                if (this.personType || this.saveId) {
                  this.activeName = "sixth";
                } else {
                  this.save(res, "sixth");
                }
              }
            }
          );
          break;
        case "sixth":
          this.$refs.personMessage.$refs.editTalentInfo.validate(
            async (valid) => {
              if (valid) {
                // 编辑
                if (this.personType || this.saveId) {
                  this.activeName = "eighth";
                } else {
                  this.save(res, "eighth");
                }
              }
            }
          );
          break;
        case "eighth":
          this.$refs.personMessage.$refs.editTalentInfo.validate(
            async (valid) => {
              if (valid) {
                // 编辑
                if (this.personType || this.saveId) {
                  this.activeName = "nine";
                } else {
                  this.save(res, "nine");
                }
              }
            }
          );
          break;
        case "nine":
          this.$refs.personMessage.$refs.editTalentInfo.validate(
            async (valid) => {
              if (valid) {
                // 编辑
                if (this.personType || this.saveId) {
                  this.save(res, "ten");
                } else {
                  this.save(res, "ten");
                }
              }
            }
          );
          break;
        case "ten":
          //最终组装的数组
          let arr = [];
          //开始添加
          this.$refs.labelInfo.peronLabelParam.personKeywordAddParamList.forEach(
            (item) => {
              let keyword = item.keyword;
              let keywordDescribe = item.keywordDescribe;
              let personLabelList = [];
              // 遍历我添加的数组
              item.personLabelList.forEach((child) => {
                // 遍历总数组
                this.$refs.labelInfo.labelOptions.forEach((item1) => {
                  if (item1.labelKeywordResultList[0].id == child.labelId) {
                    item1.labelKeywordResultList.forEach((name) => {
                      name = {
                        groupLabelId: name.groupLabelPrefix,
                        groupLabelName: name.groupLabelType,
                        labelId: name.id,
                        labelName: name.labelName,
                        labelStatus:
                          item1.labelKeywordResultList[0].id == name.id ? 1 : 0,
                      };
                      personLabelList.push(name);
                    });
                  }
                });
              });

              let all = {
                keyword: keyword,
                keywordDescribe: keywordDescribe,
                personLabelList: personLabelList,
              };
              arr.push(all);
            }
          );

          // 最终提交后端的数据
          let peronLabelParam = {
            keywordVersion: this.$refs.labelInfo.peronLabelParam.keywordVersion, //关键字版本
            personId: this.saveId ? this.saveId : this.personData.personCode,
            personKeywordAddParamList: arr,
          };
          // 判断是编辑还是新增 1编辑  0新增
          if (!this.personType) {
            // 如果未保存基本信息
            if (!this.saveId) {
              this.$message.error("请先保存基本信息的数据再进行下一步操作！");
            } else {
              this.$refs.labelInfo.$refs.labelInfoForm.validate(
                async (valid) => {
                  if (valid) {
                    res = await insertPersonLabel(peronLabelParam);
                    if (res.code == 200) {
                      this.$message.success("新增成功!");
                      this.activeName = "eleven";
                    } else {
                      this.$message.error("新增失败，请稍候重试!");
                    }
                  }
                }
              );
            }
          } else {
            this.$refs.labelInfo.$refs.labelInfoForm.validate(async (valid) => {
              if (valid) {
                res = await updatePersonLabel(peronLabelParam);
                if (res.code == 200) {
                  this.$message.success("编辑成功!");
                  this.activeName = "eleven";
                } else {
                  this.$message.error("编辑失败，请稍候重试!");
                }
              }
            });
          }
          break;
        case "eleven":
          if (this.saveId || this.personData.personCode) {
            let query = [];

            this.$refs.labelManagement.radiolist.forEach((el) => {
              if (el.labelparam.labelNameList.length > 0) {
                el.labelparam.personId = this.saveId
                  ? this.saveId
                  : this.personData.personCode;
                query.push(el.labelparam);
              }
            });
            if (query.length > 0) {
              if (!this.$refs.labelManagement.labeList.length) {
                insertPersonLabelManual(query).then((response) => {
                  if (response.code == 200) {
                    this.$message.success("保存成功");
                    this.activeName = "twelve";
                  } else {
                    this.$message.error(response.data.message);
                  }
                });
              } else {
                updatePersonLabelManual(query).then((response) => {
                  if (response.code == 200) {
                    this.$message.success("编辑成功");
                    this.activeName = "twelve";
                  } else {
                    this.$message.error(response.data.message);
                  }
                });
              }
            } else {
              this.$message.warning("请勾选标签！");
            }
          } else {
            this.$message.error("请先保存基本信息的数据再进行下一步操作！");
          }
          break;
        case "twelve":
          break;

        default:
          break;
      }
    },
    // 活动人才数据-学术机构人才---标签信息查询
    async selectPersonLabelDetail() {
      const res = await selectPersonLabelDetail({
        personId: this.personData.personCode,
      });
      if (res.code == 200) {
        // 如果请求的标签数据为null的情况，就处理下
        if (!res.data.personKeywordAddParamList) {
          this.$refs.labelInfo.peronLabelParam = {
            personId: "", // 企业机构主键
            keywordVersion: "", // 关键字版本
            // type: null,
            personKeywordAddParamList: [
              {
                personLabelList: [
                  {
                    groupLabelId: "", // 标签组编号
                    groupLabelName: "", // 标签组名称
                    labelId: "", // 标签编号
                    labelName: "", // 标签内容
                    reportKeywordId: null,
                    id: null,
                    superiorLabel: null, // 上级标签
                  },
                ],
                keyword: "", // 关键词
                keywordDescribe: "", // 关键词数据
              },
            ],
          };
        } else {
          this.$refs.labelInfo.peronLabelParam = res.data;
          // 请求数据回来后，将标签内容也改为（标签组名称+标签内容）
          res.data.personKeywordAddParamList.forEach((item) => {
            item.personLabelList.forEach((child) => {
              child.labelName = child.groupLabelName + "/" + child.labelName;
            });
          });
        }
      }
    },
    // ===============================================================

    // 查询个人信息
    async getTalents() {
      const { data: res } = await getTalent(this.personData.personCode);
      this.enterTalentsInfo = res;
      this.enterTalentsInfo.currentTechId = this.formatString(
        this.enterTalentsInfo.currentTechId
      );
      this.enterTalentsInfo.expectedTechId = this.formatString(
        this.enterTalentsInfo.expectedTechId
      );
      this.enterTalentsInfo.fieldId = this.formatString(
        this.enterTalentsInfo.fieldId
      );
      this.enterTalentsInfo.positionType = this.formatString(
        this.enterTalentsInfo.positionType
      );
    },
    // 判断是否为字符串
    formatString(val) {
      if (!val) {
        return [];
      } else {
        return val.split(",");
      }
    },
    // 当为编辑部分，提交上部信息 更新部分个人信息
    async sureInfo() {
      const res = await updateTalents(this.enterTalentsInfo);
      this.isShowInput = false;
    },
    // 当为新增部分时，提交上部分信息
    saveCompanyInfo() {
      this.$refs.rulesTalentsInfo.validate(async (valid) => {
        if (valid) {
          const res = await addTalents(this.enterTalentsInfo);
          if (res.code === 200) {
            this.$message.success("新增成功");
          }
        }
      });
    },
    // // 全部保存
    // saveItem() {
    //   Object.assign(this.enterTalentsInfo, {
    //     Authorization: this.Authorization,
    //   });
    //   let contact = this.$refs.contactInfo.ruleForm; //联系方式的数据
    //   let workHistory = this.$refs.workHistory.form2; //工作经历
    //   let thePaper = this.$refs.thePaper.form1; //论文
    //   let thePatent = this.$refs.thePatent.form2; //专利
    //   let researchProject = this.$refs.researchProject.form2; //科研项目
    //   let extraFile = this.$refs.extraFile.form2; // 附件
    //   Object.assign(contact, {
    //     Authorization: this.Authorization,
    //   });
    //   let res;
    //   this.$refs.personMessage.$refs.editTalentInfo.validate(async (valid) => {
    //     //async
    //     if (valid) {
    //       if (this.enterTalentsInfo.currentTechChildrenId) {
    //         this.enterTalentsInfo.currentTechId =
    //           this.enterTalentsInfo.currentTechId.concat(
    //             this.enterTalentsInfo.currentTechChildrenId
    //           );
    //       }
    //       if (this.enterTalentsInfo.fieldChildrenId) {
    //         this.enterTalentsInfo.fieldId =
    //           this.enterTalentsInfo.fieldId.concat(
    //             this.enterTalentsInfo.fieldChildrenId
    //           );
    //       }
    //       if (this.enterTalentsInfo.numberCitations !== null) {
    //         this.enterTalentsInfo.numberCitations =
    //           this.enterTalentsInfo.numberCitations * 1;
    //       }
    //       if (this.enterTalentsInfo.publications !== null) {
    //         this.enterTalentsInfo.publications =
    //           this.enterTalentsInfo.publications * 1;
    //       }
    //       if (
    //         this.enterTalentsInfo.fieldId[0] == null ||
    //         this.enterTalentsInfo.fieldId[0] == "null"
    //       ) {
    //         this.enterTalentsInfo.fieldId = [];
    //       }
    //       if (
    //         this.enterTalentsInfo.expectedTechId[0] == null ||
    //         this.enterTalentsInfo.expectedTechId[0] == "null"
    //       ) {
    //         this.enterTalentsInfo.expectedTechId = [];
    //       }
    //       if (
    //         this.enterTalentsInfo.currentTechId[0] == null ||
    //         this.enterTalentsInfo.currentTechId[0] == "null"
    //       ) {
    //         this.enterTalentsInfo.currentTechId = [];
    //       }
    //       const params = {
    //         personDto: this.enterTalentsInfo, // 个人信息
    //         personContact: contact, // 其他信息
    //         peDtos: workHistory, // 工作经历
    //         prEDtos: [],
    //         personEducationals: [],
    //         languages: [],
    //         additionalInformations: {},
    //         thesisDtos: thePaper, // 论文
    //         patents: thePatent, // 专利
    //         projects: researchProject, // 科研项目
    //         personFiles: extraFile, // 附件
    //       };
    //       if (this.personType) {
    //         // 编辑
    //         res = await updateTalents(this.enterTalentsInfo);
    //       } else {
    //         // 新增
    //         if (this.enterTalentsInfo.name == "") {
    //           this.$message.error("请填写人才名称");
    //         } else {
    //           res = await addTalents(params);
    //         }
    //       }
    //       if (res.code === 200) {
    //         this.$message.success(`${this.personType ? "编辑" : "新增"}成功`);
    //         this.$router.go(-1);
    //       }
    //     }
    //   });
    // },
    // 返回
    cancelItem() {
      this.$router.push({
        name: "Talents",
      });
    },
    editClick() {
      this.isShowInput = true;
    },
  },
};
</script>

<style lang="less" scoped>
.company {
  background: #fff;
  padding: 20px 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .company_left {
    display: flex;
    .info {
      margin-left: 20px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .fullName {
        display: flex;
        align-items: center;
        .el-button {
          margin-left: 10px;
        }
        .companyFullName {
          font-size: 24px;
          color: #363e4d;
        }
        i {
          margin-left: 10px;
        }
      }

      .updateTime {
        color: #7c7f8e;
        font-size: 12px;
        margin-top: 5px;
      }
    }
  }
  .company_right {
    font-size: 12px;
    color: #7c7f8e;
  }
}
/deep/ .el-tabs__content {
  overflow: auto;
}
.companyInfo {
  background: #fff;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  .el-form-item {
    margin-bottom: 0;
  }
  .avatar {
    margin-right: 20px;
  }
}

.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}

/deep/ .el-card__body {
  padding: 0px 20px 0px 20px;
}

/deep/ .jr-iconjr-icon-edit1:hover {
  color: #4d77ff;
  cursor: pointer;
}
</style>
